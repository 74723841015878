<template>
  <div class="contanter_return">
    <headerTab style="margin-left:10px"></headerTab>
    <div class="return_text">
      <div :class="selectShow?'bgc':''" @click="returnBtn">审核退款</div>
      <div style="position:relative" :class="selectShow==false?'bgc':''" @click="returnBtn">转让退款<span class="icon">{{total}}</span></div>
    </div>
    <div class="header_list" v-if="selectShow">
      <div>退款时间</div>
      <div>行为</div>
      <div>支付方式</div>
      <div>退款状态</div>
    </div>
    <div class="header_list" v-if="selectShow==false">

      <div>时间</div>
      <div>行为</div>
      <div>退款状态</div>
      <div>操作</div>
    </div>
    <div class="box" @scroll="onScroll" v-if="selectShow">
      <div class="header_list1" v-for="(item,index) in tableData" :key="index">
        <div>{{item.dataText}}</div>
        <div>{{item.text}}</div>
        <div v-if="item.union_type==2||item.union_type==3">{{item.audit.pay_type==1?"线下支付":"微信支付"}}<br />金额{{item.audit.pay_amount}}</div>
        <div v-if="item.union_type==1">{{item.audit.pay_type==1?"线下支付":"微信支付"}}<br />金额{{item.audit.pay_amount}}</div>
        <div v-if="item.union_type==9">{{item.audit.pay_type==1?"线下支付":"微信支付"}}<br />金额{{item.audit.pay_amount}}</div>
        <div v-if="item.union_type==10">{{item.audit.pay_type==1?"线下支付":"微信支付"}}<br />金额{{item.audit.pay_amount}}</div>
        <div v-if="item.union_type==4">线下支付<br />(股份转让)<br />金额{{item.audit.pay_amount}}</div>
        <div v-if="item.union_type==5">线下支付(居间)<br />金额{{item.audit.pay_amount}}</div>
        <div v-if="item.union_type==6">线上支付<br />(卖家运费)<br />金额{{item.audit.pay_amount}}</div>
        <div v-if="item.union_type==7">线上支付<br />(买家运费)<br />金额{{item.audit.pay_amount}}</div>
        <div v-if="item.union_type==8">线下支付 <br />(卖家退回打款)<br />金额{{item.audit.pay_amount}}</div>
        <div style="color:#FF3E46" v-if="item.union_type==5">{{item.audit.payment_refund_state==1?"待处理":(item.audit.payment_refund_state==2?"退款成功":'非退款')}}</div>
        <div style="color:#FF3E46" v-if="item.union_type==6">{{item.audit.sales_refund_state==1?"待处理":(item.audit.sales_refund_state==2?"退款成功":'非退款')}}</div>
        <div style="color:#FF3E46" v-if="item.union_type==7">{{item.audit.trade_refund_state==1?"待处理":(item.audit.trade_refund_state==2?"退款成功":'非退款')}}</div>
        <div style="color:#FF3E46" v-if="item.union_type==8">{{item.audit.remit_refund_state==1?"待处理":(item.audit.remit_refund_state==2?"退款成功":'非退款')}}</div>
        <div style="color:#FF3E46" v-if="item.union_type==9">{{item.audit.refund_state==1?"待处理":(item.audit.refund_state==2?"退款成功":'非退款')}}</div>
        <div style="color:#FF3E46" v-if="item.union_type==10">{{item.audit.refund_state==1?"待处理":(item.audit.refund_state==2?"退款成功":'非退款')}}</div>
        <div style="color:#FF3E46" v-if="item.union_type==3">{{item.audit.refund_state==1?"待处理":(item.audit.refund_state==2?"退款成功":'非退款')}}</div>

      </div>
    </div>

    <div class="box" @scroll="onScroll" v-if="selectShow==false">
      <div class="header_list1" v-for="(item,index) in dataList" :key="index">
        <div>{{item.return_at}}</div>
        <div>转让退款</div>
        <div style="color:#FF3E46">{{item.remit_refund_state==1?"待处理":(item.remit_refund_state==2?"退款成功":'非退款')}}</div>
        <div class="itemBtn">
          <div style="background:#07C160" v-if="item.remit_refund_state==1" @click="dispose(1,item)">处理</div>
          <div @click="dispose(2,item)" v-if="item.remit_refund_state!=1">详情</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import toast from "@/components/toast";
// import * as apiCommon from "@/api/common";
// import Cookies from 'js-cookie'
// import catSignature from "../../components/catSignature.vue";
import headerTab from "../../components/headerTab.vue";
import { Toast } from "vant";
import { returnorder_api, payReturnorder_api } from "@/api/deal";
export default {
  data() {
    return {
      queryList: {
        page: 1,
        limit: 10
      },
      tableData: [],
      dataList: [],
      selectShow: true,
      total:0
    };
  },
  created() {
    document.title = "退款记录";
    this.getList();
    this.getPayList();
  },
  components: {
    // catSignature,
    // toast
    headerTab
  },
  methods: {
    returnBtn() {
      this.selectShow = !this.selectShow;
      if (this.selectShow) {
        this.tableData = [];
        this.queryList.page = 1;
        this.getList();
      } else {
        this.dataList = [];
        this.queryList.page = 1;
        this.getPayList();
      }
    },
    onScroll(e) {
      let scrollTop = e.target.scrollTop;

      let scrollHeight = e.target.scrollHeight;

      let offsetHeight = Math.ceil(e.target.getBoundingClientRect().height);

      let currentHeight = scrollTop + offsetHeight;

      if (currentHeight >= scrollHeight) {
        this.queryList.page++;
        this.getList();
      }
    },
    getList() {
      returnorder_api(this.queryList).then(res => {
        console.log(res.data);
        if (res.code == 0) {
          this.tableData = this.tableData.concat(res.data);
          this.tableData.forEach(e => {
            if (e.union_type == 1) {
              e.text = "会员";
            } else if (e.union_type == 2) {
              e.text = "股东";
            } else if (e.union_type == 3) {
              e.text = "工分兑换";
            } else if(e.union_type==9){
              e.text = "会员续费";
            } else if(e.union_type==10){
              e.text = "合约续费";
            } else{
              e.text = "工分转让";
            }
            var time = new Date(e.create_time * 1000);
            var y = time.getFullYear();
            var m = time.getMonth() + 1;
            var d = time.getDate();
            var h = time.getHours();
            var mm = time.getMinutes();
            var s = time.getSeconds();
            e.dataText =
              y +
              "/" +
              (m > 9 ? m : "0" + m) +
              "/" +
              (d > 9 ? d : "0" + d) +
              " " +
              (h > 9 ? h : "0" + h) +
              ":" +
              (mm > 9 ? mm : "0" + mm) +
              ":" +
              (s > 9 ? s : "0" + s);
          });
          if (this.tableData.length == 0) {
            Toast({
              message: "暂无数据",
              icon: "success",
              duration: 1000
            });
          } else {
            if (res.data.length == 0) {
              Toast({
                message: "没有更多了",
                icon: "success",
                duration: 1000
              });
            }
          }
        }
      });
    },
    getPayList() {
      payReturnorder_api(this.queryList).then(res => {
        console.log(res.data);
        if (res.code == 0) {
          this.dataList = this.dataList.concat(res.data);
          this.total = res.pagination.remit_count
          this.dataList.forEach(e => {
            if (e.union_type == 1) {
              e.text = "会员";
            } else if (e.union_type == 2) {
              e.text = "股东";
            } else if (e.union_type == 3) {
              e.text = "工分兑换";
            } else {
              e.text = "工分转让";
            }
            var time = new Date(e.create_time * 1000);
            var y = time.getFullYear();
            var m = time.getMonth() + 1;
            var d = time.getDate();
            var h = time.getHours();
            var mm = time.getMinutes();
            var s = time.getSeconds();
            e.dataText =
              y +
              "/" +
              (m > 9 ? m : "0" + m) +
              "/" +
              (d > 9 ? d : "0" + d) +
              " " +
              h +
              ":" +
              mm +
              ":" +
              s;
          });
          if (this.dataList.length == 0) {
            Toast({
              message: "暂无数据",
              icon: "success",
              duration: 1000
            });
          } else {
            if (res.data.length == 0) {
              Toast({
                message: "没有更多了",
                icon: "success",
                duration: 1000
              });
            }
          }
        }
      });
    },
    dispose(index,item){
      this.$router.push(`./returnDetail?index=${index}&id=${item.trade_id}`)
    }
  }
};
</script>
<style>
.icon{
  position: absolute;
  top: -10px;
  right:-10px;
  width:20px;height:20px;border-radius:50%;
  text-align: center;
  line-height: 20px;
  background-color: red;
  color:#fff;
  font-size: 20px
}
.van-toast {
  width: 300px;
  min-height: 120px;
}
.van-toast__text {
  font-size: 35px;
}
.van-icon {
  font-size: 40px;
}
.van-dialog {
  width: 500px;
  font-size: 35px !important;
}
.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}
.van-button {
  font-size: 25px;
  line-height: 40px;
}
.contanter_return {
  box-sizing: border-box;
}
.header_list {
  width: 750px;
  height: 75px;
  line-height: 75px;
  background: #efefef;
  opacity: 1;
  border-radius: 0px;
  display: flex;
}
.header_list1 {
  width: 750px;
  height: 75px;
  /* line-height: 75px; */
  /* background: #efefef; */
  opacity: 1;
  border-radius: 0px;
  display: flex;
  margin-top: 30px;
}
.box {
  overflow-y: auto;
  height: 1000px;
}
.header_list div {
  flex: 1;
  text-align: center;
  font-size: 26px;
}
.header_list1 div {
  flex: 1;
  text-align: center;
  font-size: 22px;
  border-bottom: 1px solid #eee;
}
.return_text {
  font-size: 35px;
  color: #2e2e30;
  display: flex;
}
.return_text div {
  margin: 45px 30px 20px;
}
.return_text .bgc {
  color: #deb77a;
  border-bottom: 2px solid #deb77a;
  padding-bottom: 20px;
}
.itemBtn {
  display: flex;
}
.itemBtn div {
  width: 90px;
  height: 40px;
  background: #0f87ff;
  opacity: 1;
  border-radius: 10px;
  text-align: center;
  line-height:40px;
  font-size: 21px;color:#fff;
  margin-right: 10px
}

</style>
